import { createReducer } from "@reduxjs/toolkit";
import {
  ADD_COMPONENT,
  CHANGE_COMPONENT,
  CHANGE_CURRENT_MENU_ITEM,
  CHANGE_HEADER_FOOTER,
  CHANGE_SCREEN,
  COMPONENT_ERROR,
  COPY_COMPONENT,
  DELETE_COMPONENT,
  EDIT_COMPONENT,
  GET_SCREEN,
  GET_APP_STYLE,
  INCREASE_HEIGHT,
  SCREEN_LOADING,
  APP_STYLE_ERROR,
  APP_STYLE_LOADING,
  DELETE_MENU_ITEM,
  EDIT_MENU_ITEM,
  EDIT_MENU_ITEM_ERROR,
  EDIT_MENU_ITEM_LOADING,
  GET_INTERNAL_LOADING,
  GET_INTERNAL_ERROR,
  GET_INTERNAL,
  MENU_ITEM_ERROR,
  MENU_ITEM_LOADING,
  GET_MENU_ITEM,
  COPY_MENU_ITEM_LOADING,
  COPY_MENU_ITEM_ERROR,
  COPY_MENU_ITEM,
  CHANGE_SHEET_COMPONENT,
  CHANGE_SHEET_COMPONENT_LOADING,
} from "../actions/types";
const single = {
  error: "",
  loading: false,
  data: {},
};
const initialState = {
  error: "",
  loading: false,
  errorItem: "",
  loadingItem: false,
  menu: {},
  items: [],
  tabs: [],
  templateId: "",
  currentMenuItem: {},
  currentScreen: {},
  screenLoading: false,
  screenHeight: 0,
  upHeight: 0,
  downHeight: 0,
  middleHeight: 0,
  dataInternal: [],
  errorInternal: "",
  loadingInternal: false,
  loadingSheet: false,
  single,
};

const appStyleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APP_STYLE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CHANGE_SHEET_COMPONENT_LOADING, (state) => {
      state.loadingSheet = true;
    })
    .addCase(SCREEN_LOADING, (state) => {
      state.screenLoading = true;
    })
    .addCase(APP_STYLE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(EDIT_MENU_ITEM_ERROR, (state, { payload }) => {
      state.errorItem = payload;
      state.loadingItem = false;
    })
    .addCase(EDIT_MENU_ITEM_LOADING, (state) => {
      state.loadingItem = true;
    })

    .addCase(COPY_MENU_ITEM_ERROR, (state, { payload }) => {
      state.errorItem = payload;
      state.loadingItem = false;
    })
    .addCase(COPY_MENU_ITEM_LOADING, (state) => {
      state.loadingItem = true;
    })

    .addCase(MENU_ITEM_ERROR, (state, { payload }) => {
      state.single.error = payload;
      state.single.loading = false;
    })
    .addCase(MENU_ITEM_LOADING, (state) => {
      state.single.data = "";
      state.single.error = "";
      state.single.loading = true;
    })
    .addCase(GET_MENU_ITEM, (state, { payload }) => {
      state.single.data = payload;
      state.single.loading = false;
    })

    .addCase(COMPONENT_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_APP_STYLE, (state, { payload }) => {
      state.menu = payload?.menu;
      state.templateId = payload?.items?.[0]?.flow?.[0]?.templateId;
      let items = payload?.items?.map((obj) => ({
        ...obj,
        id: Number(obj.id),
      }));
      let sortItems = items?.sort((a, b) => (a.orders < b.orders ? -1 : 1));
      state.items = sortItems;
      state.tabs = payload?.tabs;
      state.currentMenuItem = sortItems.length ? sortItems[0] : {};
      state.loading = false;
    })
    .addCase(EDIT_MENU_ITEM, (state, { payload }) => {
      const index = state.items.findIndex((e) => e?.id === payload?.id);
      state.items[index].orders = payload?.orders;
      state.items[index].canNavigate = payload?.canNavigate;
      state.items[index].internalNavigate = payload?.internalNavigate;
      state.items[index].name = payload?.name;
      state.items[index].nameAr = payload?.nameAr;
      state.items[index].description = payload?.description;
      state.items[index].descriptionAr = payload?.descriptionAr;
      state.items[index].header = payload?.header;
      state.items[index].footer = payload?.footer;
      state.items[index].link = payload?.link;
      state.items = state.items?.sort((a, b) => (a.orders < b.orders ? -1 : 1));
      state.loadingItem = false;
    })
    .addCase(COPY_MENU_ITEM, (state, { payload }) => {
      state.items.push(payload);
      state.loadingItem = false;
    })
    .addCase(GET_INTERNAL_LOADING, (state) => {
      state.loadingInternal = true;
    })
    .addCase(GET_INTERNAL_ERROR, (state, { payload }) => {
      state.errorInternal = payload;
      state.loadingInternal = false;
    })
    .addCase(GET_INTERNAL, (state, { payload }) => {
      state.dataInternal = payload;
      state.loadingInternal = false;
    })
    .addCase(GET_SCREEN, (state, { payload }) => {
      const data = {};

      Object.entries(payload)?.forEach(([key, value]) => {
        if (Array.isArray(value) && value?.length > 0) {
          let byBlockId = [];
          value.forEach((e) => {
            byBlockId[e?.blockId - 1 > 0 ? e?.blockId - 1 : 0] = byBlockId[
              e?.blockId - 1 > 0 ? e?.blockId - 1 : 0
            ]
              ? [...byBlockId[e?.blockId - 1 > 0 ? e?.blockId - 1 : 0], e]
              : [e];
          });

          byBlockId = Array.from(byBlockId, (val) => val ?? [{}]);
          data[key] = byBlockId;
        } else {
          data[key] = value;
        }
      });

      state.currentScreen = data;
      state.upHeight =
        Math?.max(
          data?.up?.flat()?.length,
          data?.upRight?.flat()?.length,
          data?.upLeft?.flat()?.length
        ) || 1;

      state.downHeight =
        Math?.max(
          data?.down?.flat()?.length,
          data?.downRight?.flat()?.length,
          data?.downLeft?.flat()?.length
        ) || 1;

      state.middleHeight =
        Math?.max(
          data?.right?.flat()?.length,
          data?.left?.flat()?.length,
          ![29, 59].includes(state?.currentScreen?.layoutId)
            ? 0
            : data?.middle?.flat()?.length
        ) || 1;

      state.screenLoading = false;
    })
    .addCase(INCREASE_HEIGHT, (state, { payload }) => {
      if (payload === "up") {
        state.upHeight++;
      }
      if (payload === "down") {
        state.downHeight++;
      }
      if (payload === "middle") {
        state.middleHeight++;
      }
    })
    .addCase(CHANGE_CURRENT_MENU_ITEM, (state, { payload }) => {
      state.currentMenuItem = payload || {};
    })
    .addCase(DELETE_MENU_ITEM, (state, { payload }) => {
      state.items = state.items?.filter((e) => e?.id !== payload);
      state.currentMenuItem = state?.items?.length ? state?.items[0] : {};
      state.loading = false;
    })
    .addCase(CHANGE_COMPONENT, (state, { payload }) => {
      const index = state?.currentScreen[payload?.positionText][
        payload?.data?.blockId - 1 > 0 ? payload?.data?.blockId - 1 : 0
      ]?.findIndex((e) => e?.id === payload?.data?.id);

      state.currentScreen[payload?.positionText][
        payload?.data?.blockId - 1 > 0 ? payload?.data?.blockId - 1 : 0
      ][index] = payload?.data;
    })
    .addCase(CHANGE_SHEET_COMPONENT, (state, { payload }) => {
      const index = state?.currentScreen[payload?.positionText][
        payload?.data?.blockId - 1 > 0 ? payload?.data?.blockId - 1 : 0
      ]?.findIndex((e) => e?.id === payload?.ids);

      state.currentScreen[payload?.positionText][
        payload?.data?.blockId - 1 > 0 ? payload?.data?.blockId - 1 : 0
      ][index] = { ...payload?.data };

      state.loadingSheet = false;
    })
    .addCase(EDIT_COMPONENT, (state, { payload }) => {
   

      const index = state?.currentScreen[payload?.positionText][
        payload?.oldBlockId - 1 > 0 ? payload?.oldBlockId - 1 : 0
      ].findIndex((e) => {
        return e.id === payload?.data?.id;
      });
      state.currentScreen[payload?.positionText][
        payload?.oldBlockId - 1 > 0 ? payload?.oldBlockId - 1 : 0
      ][index] = payload.data;
    })
    .addCase(DELETE_COMPONENT, (state, { payload }) => {
      const newState = state?.currentScreen[payload?.positionText][
        payload?.blockId - 1 > 0 ? payload?.blockId - 1 : 0
      ].filter((e) => e.id !== payload?.componentId);

      if (newState?.length === 0) newState.push({});

      state.currentScreen[payload?.positionText][
        payload?.blockId - 1 > 0 ? payload?.blockId - 1 : 0
      ] = newState;
    })
    .addCase(ADD_COMPONENT, (state, { payload }) => {
      const resetHeights = () => {
        if (payload?.positionText.includes("up")) {
          state.upHeight =
            Math?.max(
              state?.currentScreen?.up?.flat()?.length,
              state?.currentScreen?.upRight?.flat()?.length,
              state?.currentScreen?.upLeft?.flat()?.length
            ) || 1;
        } else if (payload?.positionText.includes("down")) {
          state.downHeight =
            Math?.max(
              state?.currentScreen?.down?.flat()?.length,
              state?.currentScreen?.downRight?.flat()?.length,
              state?.currentScreen?.downLeft?.flat()?.length
            ) || 1;
        } else {
          state.middleHeight =
            Math?.max(
              state?.currentScreen?.right?.flat()?.length,
              state?.currentScreen?.left?.flat()?.length,
              ![29, 59].includes(state?.currentScreen?.layoutId)
                ? 0
                : state?.currentScreen?.middle?.flat()?.length
            ) || 1;
        }
      };

      const addComponentIfBlockSlotExist = () => {
        //if the slot is empty => contain empty object
        if (
          state?.currentScreen[payload?.positionText][
            payload?.data?.blockId - 1
          ]?.length === 1 &&
          !state?.currentScreen[payload?.positionText][
            payload?.data?.blockId - 1
          ][0]?.id
        ) {
          state.currentScreen[payload?.positionText][
            payload?.data?.blockId - 1
          ][0] = payload?.data;
        } else {
          state?.currentScreen[payload?.positionText][
            payload?.data?.blockId - 1
          ].push(payload?.data);
          resetHeights();
        }
      };

      const addComponentIfNewBlockSlot = () => {
        let fillEmptySlots = false;

        if (
          payload?.data?.blockId >
          state.currentScreen[payload?.positionText]?.length + 1
        )
          fillEmptySlots = true;

        state.currentScreen[payload?.positionText][payload?.data?.blockId - 1] =
          [payload?.data];

        if (fillEmptySlots) {
          state.currentScreen[payload?.positionText] = Array.from(
            state.currentScreen[payload?.positionText],
            (val) => val ?? [{}]
          );
        }
      };

      if (
        state?.currentScreen[payload?.positionText][payload?.data?.blockId - 1]
      ) {
        addComponentIfBlockSlotExist();
      } else {
        addComponentIfNewBlockSlot();
        resetHeights();
      }
    })
    .addCase(COPY_COMPONENT, (state, { payload }) => {
      const resetHeights = () => {
        if (payload?.positionText.includes("up")) {
          state.upHeight =
            Math?.max(
              state?.currentScreen?.up?.flat()?.length,
              state?.currentScreen?.upRight?.flat()?.length,
              state?.currentScreen?.upLeft?.flat()?.length
            ) || 1;
        } else if (payload?.positionText.includes("down")) {
          state.downHeight =
            Math?.max(
              state?.currentScreen?.down?.flat()?.length,
              state?.currentScreen?.downRight?.flat()?.length,
              state?.currentScreen?.downLeft?.flat()?.length
            ) || 1;
        } else {
          state.middleHeight =
            Math?.max(
              state?.currentScreen?.right?.flat()?.length,
              state?.currentScreen?.left?.flat()?.length,
              ![29, 59].includes(state?.currentScreen?.layoutId)
                ? 0
                : state?.currentScreen?.middle?.flat()?.length
            ) || 1;
        }
      };

      state.currentScreen[payload?.positionText][
        payload?.data?.blockId - 1 > 0 ? payload?.data?.blockId - 1 : 0
      ].push(payload?.data);
      resetHeights();
    })
    .addCase(CHANGE_HEADER_FOOTER, (state, { payload }) => {
      if (payload?.header_id) {
        state.menu.header = payload?.data?.header;
        state.menu.headerImage = payload?.data?.headerImage;
      }
      if (payload?.footer_id) {
        state.menu.footer = payload?.data?.footer;
        state.menu.footerImage = payload?.data?.footerImage;
      }
    })

    .addCase(CHANGE_SCREEN, (state, { payload }) => {
      state.currentScreen.screenImage = payload?.screenImage;
      state.currentScreen.layoutId = payload?.layoutId;
    });
});

export default appStyleReducer;
