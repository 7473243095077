import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  defaults,
  auth,
  apps,
  appStyle,
  module,
  screen,
  styles,
  moduleType,
  formRules,
  tabsAndForms,
  coreComponent,
  actions,
  fieldNames,
  items,
  filter,
  categories,
  categoryValues,
  tags,
  industries,
  packages,
  languages,
  appRules,
  googleForms,
  rules,
  entities,
  statistics,
  appTheme,
  appTypes,
  appType,
  allAction,
  coreLayout,
  design,
  menu,
  templateApps,
  componentCategories,
  buttons,
  payment,
  recommend,
  colorPalette,
  objective,
  appSystem,
  appsTypes,
  systemLocalizations,
  entityLocalizations,
  fileFieldsAr,
  fileFieldsEn,
  template,
  sheet,
} from "./reducers";

const store = configureStore({
  reducer: {
    template,
    sheet,
    appsTypes,
    fileFieldsEn,
    fileFieldsAr,
    systemLocalizations,
    entityLocalizations,
    recommend,
    appSystem,
    objective,
    colorPalette,
    defaults,
    payment,
    menu,
    coreLayout,
    design,
    appType,
    allAction,
    filter,
    auth,
    apps,
    appStyle,
    module,
    screen,
    styles,
    moduleType,
    formRules,
    tabsAndForms,
    coreComponent,
    actions,
    fieldNames,
    items,
    categories,
    categoryValues,
    tags,
    industries,
    packages,
    languages,
    appRules,
    googleForms,
    rules,
    entities,
    statistics,
    appTheme,
    appTypes,
    templateApps,
    componentCategories,
    buttons,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(thunkMiddleware);
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
