import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_FOOTER,
  CREATE_HEADER,
  CREATE_ITEM,
  CREATE_SHEET,
  EDIT_SHEET,
  FOOTER_SHEET,
  GET_SHEET,
  HEADER_SHEET,
  SHEET_LOADING,
} from "../actions/types";

const initialState = {
  header: null,
  footer: null,
  data: [],
  loading: false,
};

const sheetReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SHEET_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(GET_SHEET, (state, { payload }) => {
      state.data = payload;
    })
    .addCase(CREATE_SHEET, (state, { payload }) => {
      console.log("payload", payload);
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);

      state.data = [...state.data]?.sort((a, b) => a.blockId - b.blockId);
    })
    .addCase(EDIT_SHEET, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);

      state.data[index] = payload;
      state.loading = false;
    })

    .addCase(CREATE_ITEM, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload);
      state.loading = false;
    })

    .addCase(HEADER_SHEET, (state, { payload }) => {
      state.header = payload;
    })
    .addCase(FOOTER_SHEET, (state, { payload }) => {
      state.footer = payload;
    })

    .addCase(CREATE_FOOTER, (state) => {
      state.loading = false;
    })
    .addCase(CREATE_HEADER, (state) => {
      state.loading = false;
    });
});

export default sheetReducer;
